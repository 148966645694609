'use client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { isProduction } from '@/common/utils/NextFunctions';

if (typeof window !== 'undefined' && isProduction()) {
  posthog.init(
    process.env.NEXT_PUBLIC_KOMO_ENVIRONMENT === 'production'
      ? 'phc_TbVpmTwsph5l5JsX11qrNtJ0tZuV0Am54NuBx6J4CVQ'
      : 'phc_cEjNCs9BDEFDflQujTr2SABOFU1gpuHLNQkpBwA0a2q',
    {
      api_host: 'https://app.posthog.com',
      capture_pageview: false // Disable automatic pageview capture, as we capture manually
    }
  );
}

export function AppPostHogProvider({ children }) {
  if (!isProduction()) return children;

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
